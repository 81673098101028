import { useEffect, useState, useMemo, useCallback } from "react";
import { useUser, User } from "./useUser";
import { useLocalStorage } from "./useLocalStorage";

export const useAuth = () => {
  // we can re export the user methods or object from this hook
  const { user, addUser, removeUser, setUser, loggedIn, setLoggedIn } = useUser();
  const { getItem, setItem } = useLocalStorage();

  useEffect(() => {
    (async() => {
      const _user = await getItem("user");
      if (_user) {
        addUser(JSON.parse(_user));
        setLoggedIn(true);
      }
    })()
  }, [addUser, getItem, setLoggedIn]);

  const updateUser = useCallback((_user: User) => {
    const currentUserJSON = localStorage.getItem('user');
    let currentUser = currentUserJSON ? JSON.parse(currentUserJSON) : {};
    currentUser = { ...currentUser, ..._user };
    setItem('user', JSON.stringify(currentUser));
  },[setItem]);

  const login = useCallback((_user: User) => {
    addUser(_user);
  },[addUser]);

  const logout = useCallback(() => {
    removeUser();
    setLoggedIn(false);
  },[removeUser, setLoggedIn]);

  const authMethods = useMemo(() => ({
    user, login, logout, setUser, loggedIn, updateUser
  }), [user, loggedIn, login, logout, setUser, updateUser]);

  return authMethods;
};