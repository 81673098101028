import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@wethegit/react-autoplay-video/style.css"
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import {Welcome} from './pages/welcome';
import {Login} from './pages/login';
import {Signup} from './pages/signup';
import {Home} from './pages/home';
import {Suggestions} from './pages/suggestions';
import {SavedNames} from './pages/saved';
import {ErrorPage} from './pages/error';
import { useAuth } from './hooks/useAuth';
import { AuthProvider } from './contexts/authContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: <Signup />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/suggestions",
    element: <Suggestions />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/saved",
    element: <SavedNames />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (<AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>);
}

export default App;
