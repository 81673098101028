import React, {useState, useEffect, useCallback} from 'react';
import '../css/layout.css';
import {Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

export const SavedNames = () => {
	const navigate = useNavigate();
	const {user, logout} = useAuth();
	const [content, setContent] = useState([]);

	const loadContent = useCallback(async() => {
		let response = await fetch(`https://babynames-worker-dev.yasminek.workers.dev/app/saved_names?email=${user?.email}`,
									{
										method:'get',
										headers: {'Content-Type': 'application/json','Authorization':`Bearer ${user?.token}`}
									});
		//console.log(JSON.stringify(await response.json()));
		let data = await response.json();
		setContent(data.names);
	},[user?.token, user?.email, setContent]);

	useEffect(() => {
		(async() => {
			await loadContent()
		})();
	},[loadContent]);

	const onDeleteAll = useCallback(async() => {
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/delete_all_saved_names',
			{
				method:'post',
				headers: {'Content-Type': 'application/json','Authorization':`Bearer ${user?.token}`}
			});
		let data = await response.json();
		console.log(data);
		setContent([]);
	},[setContent, user?.token]);

	const onDeleteName = useCallback(async(name) => {
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/delete_saved_name',
			{
				method:'post',
				headers: {'Content-Type': 'application/json','Authorization':`Bearer ${user?.token}`},
				body:JSON.stringify({name})
			});
		let data = await response.json();
		console.log(data);
		let result = [];
		const updatedData = content.filter(item => item.name !== name);
		console.log(JSON.stringify(updatedData));
		setContent([...updatedData]);
	},[setContent, content, user?.token]);

	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>N</b>ames <b>T</b>rove</h1>
						</div>
						<div className="loggedInBtns d-flex align-items-center">
							<div className="loggedInBtn" onClick={() => navigate('/home')}>Home</div>
							<div className="loggedInBtn" onClick={() => navigate('/suggestions')}>Suggestions</div>
							<div className="loggedInBtn" onClick={() => navigate('/saved')}>Saved</div>
						</div>
                        <Button onClick={() => {logout(); navigate('/login')}} className="logout-btn">Logout</Button>
                    </nav>
					<Row className="d-flex justify-content-center page-content">
					    <Col xs={12} md={6}>
					        <div className="card h-100 p-3">
						            <div className="d-flex align-items-center">
						                <div><b>Saved names</b></div>
						                <div className="save-form">
						                    <button onClick={onDeleteAll} className="btn trash-btn">Delete all <i className="bi bi-trash"></i></button>
						                </div>
						            </div>
						            {content?.map((value, index) => {
							            return 	<div className="list-container pt-2">
								                    <div className="list-item d-flex align-items-center">
								                        <div>{value.name}</div>
								                        <div className="save-form">
								                            <div>suggested by: {value.suggested_by}</div>
								                        </div>
								                        <div >
								                            <input type="text" name="name" value={value.name} className="hide"/>
								                            <button onClick={() => onDeleteName(value.name)} className="btn delete-name-btn"><i className="bi bi-dash-circle"></i></button>
								                        </div>
								                    </div>
								                </div>
						            })}
					                {(content?.length === 0)&&<div className="h-100 w-100 d-flex align-items-center justify-content-center graybackground">
					                    <h5 className="graytext">No results</h5>
					                </div>}
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}