import React, {useState, useEffect, useCallback} from 'react';
import '../css/layout.css';
import {Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

export const Suggestions = () => {
	const navigate = useNavigate();
	const {user, logout} = useAuth();
	const [parent1name, setParent1name] = useState('');
	const [parent2name, setParent2name] = useState('');
	const [gender, setGender] = useState('');
	const [content, setContent] = useState([]);

	const loadContent = useCallback(async() => {
		let response = await fetch(`https://babynames-worker-dev.yasminek.workers.dev/app/suggestions?email=${user?.email}`,
									{
										method:'get',
										headers: {'Content-Type': 'application/json','Authorization':`Bearer ${user?.token}`}
									});
		let data = await response.json();
		console.log(data);
		if(data.names){
			setContent([...data?.names]);
		}else{
			setContent([]);
		}
	},[user?.token, user?.email])

	useEffect(() => {
		(async() => {await loadContent()})();
	},[loadContent]);

	const saveName = useCallback(async(name,suggested_by) => {
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/save_name',
			{
				method:'post',
				headers: {'Content-Type':'application/json', 'Authorization':`Bearer ${user?.token}`}, 
				body:JSON.stringify({name:name,suggested_by:suggested_by,saved_by:user?.email}),
			});
		const jsonResp = await response?.json();
		console.log(JSON.stringify(jsonResp));
		const updatedData = content.map((item,index) => {
			if (item.name === name) {
			  return { ...item, saved: 1 };
			}
			return item; 
		});
		console.log(JSON.stringify(updatedData));
		setContent([...updatedData]);
	},[content, user?.token, user?.email]);


	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>N</b>ames <b>T</b>rove</h1>
						</div>
						<div className="loggedInBtns d-flex align-items-center">
							<div className="loggedInBtn" onClick={() => navigate('/home')}>Home</div>
							<div className="loggedInBtn" onClick={() => navigate('/suggestions')}>Suggestions</div>
							<div className="loggedInBtn" onClick={() => navigate('/saved')}>Saved</div>
						</div>
                        <Button onClick={() => {logout(); navigate('/login')}} className="logout-btn">Logout</Button>
                    </nav>
					<Row className="h-100 d-flex justify-content-center page-content">
					    <Col xs={12} md={6}>
					        <div className="card">
					            <div className="card-body ">
					            	<h4>results</h4>
									<div className='h-100 w-100'>
										{content?.map((value, index) => {
											return 	<div className="list-item d-flex align-items-center">
														<div>{value.name }</div>
														<div className="suggested_by">suggested by: { value.suggested_by }</div>
														<div>
															<input type="text" name="name" value={ value.name } className="hide"/>
															<input type="text" name="suggested_by" value={ value.suggested_by } className="hide"/>
															{(value.saved === 1)?(<button type="submit" className="btn btn-primary save-btn" disabled>Saved</button>)
															:(<button onClick={()=>saveName(value.name,value.suggested_by)} type="submit" className="btn btn-primary save-btn">Save</button>)}
														</div>
													</div>
										})}
									</div>
									{(content?.length === 0)&&<div className="h-100 w-100 d-flex align-items-center justify-content-center graybackground">
					                    <h5 className="graytext">No results</h5>
					                </div>}
					            </div>
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}