import { useContext, useCallback } from "react";
import { AuthContext } from "../contexts/authContext";
import { useLocalStorage } from "./useLocalStorage";

export let User:{
  fullname: string;
  email: string;
  token: string;
} = {};

export const useUser = () => {
  const {_user, _loggedIn} = useContext(AuthContext);
  const [user, setUser] = _user;
  const [loggedIn, setLoggedIn] = _loggedIn;
  const { setItem } = useLocalStorage();

  const addUser = useCallback((user: User) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  },[setItem, setUser]);

  const removeUser = useCallback(() => {
    setUser(null);
    setItem("user", "");
  },[setItem, setUser]);

  return { user, addUser, removeUser, setUser, loggedIn, setLoggedIn };
};