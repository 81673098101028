import React,{useState, useCallback} from 'react';
import '../css/layout.css';
import {Button, Row, Col} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';
import {useUser} from '../hooks/useUser';

export const Login = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const {login} = useAuth();
	const {user} = useUser();
	const handleEmailInput = useCallback((e) => {
		setEmail(e.target.value);
	},[setEmail])
	const handlePasswordInput = useCallback((e) => {
		setPassword(e.target.value);
	},[setPassword])

	const handleSubmit = useCallback(async(e) => {
		e.preventDefault();
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/login',
									{
										method:'post',
										headers: {'Content-Type': 'application/json'}, 
										body:JSON.stringify({email:email,password:password}),
									});
		//console.log(JSON.stringify(await response.json()));
		let data = await response.json();
		console.log(data);
		login({fullname:data?.fullname,email:data?.email,token:data?.token});
		console.log('user ',JSON.stringify(user))
		navigate('/home');
	},[email,password,login,user,navigate]);



	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>N</b>ames <b>T</b>rove</h1>
						</div>
                        <Button onClick={() => navigate('/signup')} className="login-btn">Signup</Button>
                    </nav>
					<Row className="h-100 d-flex align-items-center justify-content-center p-3 page-content">
					    <Col xs={12} md={4}>
					        <div className="card">
					            <div className="card-body d-flex justify-content-start">
					                <form className="w-100">
					                    <div className="form-group w-100 mb-3">
					                        <input onChange={handleEmailInput} type="text" className="form-control w-100" id="parent1Name" name="email" placeholder="Email" required/>
					                    </div>
					                    <div className="form-group w-100 mb-3">
					                        <input onChange={handlePasswordInput} type="password" className="form-control w-100" id="parent2Name" name="password" placeholder="Password" required/>
					                    </div>
					                    <button type="submit" onClick={handleSubmit} className="btn btn-primary login-btn mb-3 w-100">Login</button>
					                </form>
					            </div>
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}