/* eslint-disable react-hooks/exhaustive-deps */
import React,{useRef, useState, useEffect, useCallback} from 'react';
import {Row,Col,Button,Carousel} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom'
import '../css/welcome.css';
import { AutoplayVideo } from "@wethegit/react-autoplay-video";
import babyshow1 from '../assets/babyshower1.mp4';
import babyshow2 from '../assets/babyshower2.mp4';

export const Welcome = () => {
    const navigate = useNavigate();
    const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;
    const target1Ref = useRef(null);
    const target2Ref = useRef(null);
    const target3Ref = useRef(null);
    const scrollToTarget1 = useCallback(() => {
        if (target1Ref.current) {
          target1Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[target1Ref]);
    const scrollToTarget2 = useCallback(() => {
        if (target2Ref.current) {
          target2Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[target2Ref]);
    const scrollToTarget3 = useCallback(() => {
        if (target3Ref.current) {
          target3Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[target3Ref]);

    const [name, setName] = useState('');
    const [suggested_by, setSuggestedBy] = useState('');
    const [target_email, setTargetEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    //Generate names form
    const [parent1name, setParent1name] = useState('');
    const [parent2name, setParent2name] = useState('');
    const [gender, setGender] = useState('');
    //Generated names content
    const [content, setContent] = useState([]);
    //Suggestions form & content
    const [suggestionsContent, setSuggestionsContent] = useState([]);
    //Saved content
    const [savedContent, setSavedContent] = useState([]);

    const handleSubmit = useCallback(async(e) => {
        e.preventDefault();
        let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/suggest',
                                    {
                                        method:'post',
                                        headers: {'Content-Type': 'application/json'}, 
                                        body:JSON.stringify({name,suggested_by}),
                                    });
        console.log(JSON.stringify(await response.json()));
        setSubmitted(true);
        setName('');
        setSuggestedBy('');
        setTargetEmail('');
        setTimeout(() => {
            setSubmitted(false);
        },5000);
        await loadSuggestionsContent();
    },[setSubmitted, name, suggested_by]);

    const handleGenerateNamesSubmit = useCallback(async(e) => {
        e.preventDefault();
        let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/home',
                                    {
                                        method:'post',
                                        headers: {'Content-Type':'application/json'}, 
                                        body:JSON.stringify({parent1name:parent1name,parent2name:parent2name,gender:gender}),
                                    });
        const jsonResp = await response?.json();
        console.log(JSON.stringify(jsonResp));
        setContent([...jsonResp.names]);
    },[parent1name, parent2name, gender]);

    const saveName = useCallback(async(name) => {
        let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/save_name',
            {
                method:'post',
                headers: {'Content-Type':'application/json'}, 
                body:JSON.stringify({name:name,saved_by:''}),
            });
        const jsonResp = await response?.json();
        console.log(JSON.stringify(jsonResp));
        const updatedData = content.map((item,index) => {
            if (item.name === name) {
              return { ...item, saved: true };
            }
            return item; 
        });
        console.log(JSON.stringify(updatedData));
        setContent([...updatedData]);
        await loadSavedContent();
    },[content]);

    const saveSuggestionName = useCallback(async(name,suggested_by) => {
        let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/save_name',
            {
                method:'post',
                headers: {'Content-Type':'application/json'}, 
                body:JSON.stringify({name:name,suggested_by:suggested_by,saved_by:''}),
            });
        const jsonResp = await response?.json();
        console.log(JSON.stringify(jsonResp));
        const updatedData = suggestionsContent.map((item,index) => {
            if (item.name === name) {
              return { ...item, saved: 1 };
            }
            return item; 
        });
        console.log(JSON.stringify(updatedData));
        setSuggestionsContent([...updatedData]);
        await loadSavedContent();
    },[suggestionsContent]);

    const onDeleteAll = useCallback(async() => {
        let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/delete_all_saved_names',
                                    {
                                        method:'post',
                                        headers: {'Content-Type': 'application/json'}
                                    });
        let data = await response.json();
        console.log(data);
        setSavedContent([]);
    },[setSavedContent]);

    const onDeleteName = useCallback(async(name) => {
        let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/delete_saved_name',
                                    {
                                        method:'post',
                                        headers: {'Content-Type': 'application/json'},
                                        body:JSON.stringify({name})
                                    });
        let data = await response.json();
        console.log(data);
        let result = [];
        const updatedData = savedContent.filter(item => item.name !== name);
        console.log(JSON.stringify(updatedData));
        setSavedContent([...updatedData]);
    },[setSavedContent, savedContent]);

    const loadSuggestionsContent = useCallback(async() => {
        let response = await fetch(`https://babynames-worker-dev.yasminek.workers.dev/suggestions`,
                                    {
                                        method:'get',
                                        headers: {'Content-Type': 'application/json'}
                                    });
        let data = await response.json();
        console.log(data);
        if(data.names){
            setSuggestionsContent([...data?.names]);
        }else{
            setSuggestionsContent([]);
        }
    },[]);

    const loadSavedContent = useCallback(async() => {
        let response = await fetch(`https://babynames-worker-dev.yasminek.workers.dev/saved_names`,
                                    {
                                        method:'get',
                                        headers: {'Content-Type': 'application/json'}
                                    });
        let data = await response.json();
        setSavedContent(data.names);
    },[setSavedContent]);

    useEffect(() => {
        (async() => {
            await loadSuggestionsContent();
            await loadSavedContent();
        })();
    },[loadSuggestionsContent,loadSavedContent]);

    return  <div className="welcome-content">
                <Carousel className="welcome-video">
                    <Carousel.Item interval={10000}>
                        <AutoplayVideo  src={babyshow1}
                                        posterImg=""
                                        description="This is a description of the video."
                                        prefersReducedMotion={prefersReducedMotion}
                                        style={{height:'100vh'}}
                                        className="autoPlayVideo"
                                        renderReducedMotionFallback={() => (
                                        <img src="" alt="Description of the fallback image." />
                                        )}/>
                        <Carousel.Caption>
                          <h3 className="white-text">Explore baby names</h3>
                          <h5 className="white-text">Find a unique name for your baby!</h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={10000}>
                        <AutoplayVideo  src={babyshow2}
                                        posterImg=""
                                        description="This is a description of the video."
                                        prefersReducedMotion={prefersReducedMotion}
                                        style={{height:'100vh'}}
                                        className="autoPlayVideo"
                                        renderReducedMotionFallback={() => (
                                        <img src="" alt="Description of the fallback image." />
                                        )}/>
                        <Carousel.Caption>
                          <h3 className="white-text">Suggest a baby name for your friend or loved ones</h3>
                          <h5 className="white-text">Check out potential baby names</h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <div className="welcome-section">
                    <nav className="navbar">
                        <div className="welcome-logo" onClick={() => navigate('/')}>
                            <h1 className="white-text"><b>B</b>aby <b>N</b>ames</h1>
                        </div>
                        <div className="loggedInBtns d-flex align-items-center">
                            <div className="navBtn" onClick={scrollToTarget1}>Generate Names</div>
                            <div className="navBtn" onClick={scrollToTarget2}>Suggestions</div>
                            <div className="navBtn" onClick={scrollToTarget3}>Saved</div>
                        </div>
                        {/*<Button onClick={() => navigate('/login')} className="login-btn">Login</Button>
                        <Button style={{marginLeft:15,marginRight:20}} onClick={() => navigate('/signup')} className="welcome-signup-btn">Signup</Button>*/}
                    </nav>
                    <Row className="header-content d-flex align-items-center justify-content-center">
                        <Col xs={12} md={8} className="inner-header-content">
                            <Button className="suggest-btn mb-3" onClick={scrollToTarget2}>Suggest a baby name</Button>
                        </Col>
                    </Row>
                </div>
                <div className="alt-welcome-section welcomeBgImg" ref={target1Ref}>
                    <h1 className="section-title">Generate Names</h1>
                    <Row className="h-100 d-flex justify-content-center p-3">
                        <Col xs={12} md={6}>
                            <div className="card">
                                <div className="tabs d-flex align-items-center">
                                    <div className="tab" style={{backgroundColor:'rgb(179, 208, 255, 0.3)'}}>Parent name combination</div>
                               </div>
                                <div className="card-body d-flex justify-content-start">
                                    <div className="tab1-content">
                                        <form className="w-100" action="/" method="POST">
                                            <label>Generate unique baby names by combining parent's names</label>
                                            <div className="form-group w-100">
                                                <input onChange={(e) => setParent1name(e.target.value)} type="text" className="form-control w-100" id="parent1Name" name="parent1Name" placeholder="Enter Parent 1 Name" required/>
                                            </div>
                                            <div className="form-group w-100">
                                                <input onChange={(e) => setParent2name(e.target.value)} type="text" className="form-control w-100" id="parent2Name" name="parent2Name" placeholder="Enter Parent 2 Name" required/>
                                            </div>
                                            <div className="form-group w-100">
                                                <label>Gender</label>
                                                <div className="form-check w-100">
                                                    <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="male" value="male" required/>
                                                    <label className="form-check-label" for="male">
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="form-check w-100">
                                                    <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="female" value="female" required/>
                                                    <label className="form-check-label" for="female">
                                                        Female
                                                    </label>
                                                </div>
                                            </div>
                                            <button onClick={handleGenerateNamesSubmit} type="submit" className="btn btn-primary">Generate Baby Names</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="bold">Generated names</div>
                                        <div className="save-form">
                                            <button onClick={handleGenerateNamesSubmit} className="btn repeat-btn">repeat <i className="bi bi-arrow-repeat"></i></button>
                                        </div>
                                    </div>
                                    {(content.length > 0)?(<div className="h-100 w-100">
                                        {content.map((value, index) => {
                                            return  <div className="list-item d-flex align-items-center">
                                                        <div>{value.name}</div>
                                                        <div className="save-form">
                                                            <input type="text" name="name" value={ value.name } className="hide"/>
                                                            <input type="text" name="suggested_by" value="ai" className="hide"/>
                                                            <button disabled={value?.saved?true:false} onClick={(e) => {e.preventDefault(); saveName(value.name);}} className="btn btn-primary save-btn save-generated-name" id="save-generated-name">{(value?.saved)?'Saved':'Save'}</button>
                                                        </div>
                                                    </div>
                                        })}
                                    </div>):(
                                        <div className="h-100 w-100 d-flex align-items-center justify-content-center graybackground">
                                            <h5 className="graytext">No results</h5>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="alt-welcome-section welcomeBgImg" ref={target2Ref}>
                    <h1 className="section-title">Suggestions</h1>
                    <Row className="h-100 d-flex justify-content-center p-3">
                        <Col xs={12} md={6}>
                            <div className="card">
                                <div className="card-body d-flex justify-content-start">
                                    <form className="w-100" action="/" method="POST">
                                        {submitted&&<label className="mb-3" style={{color:'green'}}>Submitted Successfully</label>}
                                        <br/>
                                        <label className="mb-3">Enter a baby name suggestion below. Thanks!</label>
                                        <div className="form-group w-100 mb-3">
                                            <input onChange={(e) => setName(e.target.value)} value={name} type="text" className="form-control w-100" id="fullname" name="fullname" placeholder="Baby Name" required/>
                                        </div>
                                        <div className="form-group w-100 mb-3">
                                            <input onChange={(e) => setSuggestedBy(e.target.value)} value={suggested_by} type="text" className="form-control w-100" id="email" name="email" placeholder="Suggested by" required/>
                                        </div>
                                        <button onClick={handleSubmit} type="submit" className="btn btn-primary login-btn mb-3 w-100">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="card">
                                <div className="card-body ">
                                    <h4>results</h4>
                                    <div className='h-100 w-100'>
                                        {suggestionsContent?.map((value, index) => {
                                            return  <div className="list-item d-flex align-items-center">
                                                        <div>{value.name }</div>
                                                        <div className="suggested_by">suggested by: { value.suggested_by }</div>
                                                        <div>
                                                            <input type="text" name="name" value={ value.name } className="hide"/>
                                                            <input type="text" name="suggested_by" value={ value.suggested_by } className="hide"/>
                                                            {(value.saved === 1)?(<button type="submit" className="btn btn-primary save-btn" disabled>Saved</button>)
                                                            :(<button onClick={()=>saveSuggestionName(value.name,value.suggested_by)} type="submit" className="btn btn-primary save-btn">Save</button>)}
                                                        </div>
                                                    </div>
                                        })}
                                    </div>
                                    {(suggestionsContent?.length === 0)&&<div className="h-100 w-100 d-flex align-items-center justify-content-center graybackground">
                                        <h5 className="graytext">No results</h5>
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="alt-welcome-section welcomeBgImg" ref={target3Ref}>
                    <h1 className="section-title">Saved Names</h1>
                    <Row className="h-100 d-flex justify-content-center p-3">
                        <Col xs={12} md={6}>
                            <div className="card h-100 p-3">
                                <div className="d-flex align-items-center">
                                    <div><b>Saved names</b></div>
                                    <div className="save-form">
                                        <button onClick={onDeleteAll} className="btn trash-btn">Delete all <i className="bi bi-trash"></i></button>
                                    </div>
                                </div>
                                {savedContent?.map((value, index) => {
                                    return  <div className="list-container pt-2">
                                                <div className="list-item d-flex align-items-center">
                                                    <div>{value.name}</div>
                                                    <div className="save-form">
                                                        <div>suggested by: {value.suggested_by}</div>
                                                    </div>
                                                    <div >
                                                        <input type="text" name="name" value={value.name} className="hide"/>
                                                        <button onClick={() => onDeleteName(value.name)} className="btn delete-name-btn"><i className="bi bi-dash-circle"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                })}
                                {(savedContent?.length === 0)&&<div className="h-100 w-100 d-flex align-items-center justify-content-center graybackground">
                                    <h5 className="graytext">No results</h5>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
}