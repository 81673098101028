import { createContext, useState, useMemo } from "react";

// Create the context
export const AuthContext = createContext(null);

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  const contextValue = useMemo(() => ({
    _user: [user, setUser],
    _loggedIn: [loggedIn, setLoggedIn]
  }), [user, loggedIn]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
