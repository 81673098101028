import React,{useState, useCallback} from 'react';
import '../css/layout.css';
import {Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useUser} from '../hooks/useUser';
import {useAuth} from '../hooks/useAuth';

export const Home = () => {
	const navigate = useNavigate();
	const {user,logout} = useAuth();
	//form 1
	const [parent1name, setParent1name] = useState('');
	const [parent2name, setParent2name] = useState('');
	const [gender, setGender] = useState('');
	//form 2
	const [ethnicBackground, setEthnicBackground] = useState('');
	//form 3
	const [religion, setReligion] = useState('');
	//form 4
	const [meaningOfName, setMeaningOfName] = useState('');
	//content
	const [content, setContent] = useState([]);
	const [tab1, setTab1] = useState(true);
	const [tab2, setTab2] = useState(false);
	const [tab3, setTab3] = useState(false);
	const [tab4, setTab4] = useState(false);

	const handleSubmit = useCallback(async(e) => {
		e.preventDefault();
		if(user?.token){
			let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/home',
										{
											method:'post',
											headers: {'Content-Type':'application/json', 'Authorization':`Bearer ${user?.token}`}, 
											body:JSON.stringify({parent1name:parent1name,parent2name:parent2name,gender:gender}),
										});
			const jsonResp = await response?.json();
			console.log(JSON.stringify(jsonResp));
			setContent([...jsonResp.names]);
		}else{
			navigate('/');
		}
	},[parent1name, parent2name, gender, user?.token, navigate]);

	const handleEthnicNameSubmit = useCallback(async(e) => {
		e.preventDefault();
		if(user?.token){
			let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/ethnic_names',
										{
											method:'post',
											headers: {'Content-Type':'application/json', 'Authorization':`Bearer ${user?.token}`}, 
											body:JSON.stringify({ethnicBackground:ethnicBackground,gender:gender}),
										});
			const jsonResp = await response?.json();
			console.log(JSON.stringify(jsonResp));
			setContent([...jsonResp.names]);
		}else{
			navigate('/');
		}
	},[gender, user?.token, ethnicBackground, navigate]);

	const handleReligiousNameSubmit = useCallback(async(e) => {
		e.preventDefault();
		if(user?.token){
			let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/religious_names',
										{
											method:'post',
											headers: {'Content-Type':'application/json', 'Authorization':`Bearer ${user?.token}`}, 
											body:JSON.stringify({religion:religion,gender:gender}),
										});
			const jsonResp = await response?.json();
			console.log(JSON.stringify(jsonResp));
			setContent([...jsonResp.names]);
		}else{
			navigate('/');
		}
	},[gender, user?.token, navigate, religion]);

	const handleMeaningfulNameSubmit = useCallback(async(e) => {
		e.preventDefault();
		if(user?.token){
			let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/meaningful_names',
										{
											method:'post',
											headers: {'Content-Type':'application/json', 'Authorization':`Bearer ${user?.token}`}, 
											body:JSON.stringify({meaningOfName:meaningOfName,gender:gender}),
										});
			const jsonResp = await response?.json();
			console.log(JSON.stringify(jsonResp));
			setContent([...jsonResp.names]);
		}else{
			navigate('/');
		}
	},[gender, user?.token, meaningOfName, navigate]);

	const saveName = useCallback(async(name) => {
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/app/save_name',
			{
				method:'post',
				headers: {'Content-Type':'application/json', 'Authorization':`Bearer ${user?.token}`}, 
				body:JSON.stringify({name:name,saved_by:user?.email}),
			});
		const jsonResp = await response?.json();
		console.log(JSON.stringify(jsonResp));
		const updatedData = content.map((item,index) => {
			if (item.name === name) {
			  return { ...item, saved: true };
			}
			return item; 
		});
		console.log(JSON.stringify(updatedData));
		setContent([...updatedData]);
	},[content, user?.token, user?.email]);

	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>N</b>ames</h1>
						</div>
						<div className="loggedInBtns d-flex align-items-center">
							<div className="loggedInBtn" onClick={() => navigate('/home')}>Home</div>
							<div className="loggedInBtn" onClick={() => navigate('/suggestions')}>Suggestions</div>
							<div className="loggedInBtn" onClick={() => navigate('/saved')}>Saved</div>
						</div>
                        <Button onClick={() => {logout(); navigate('/login')}} className="logout-btn">Logout</Button>
                    </nav>
					<Row className="h-100 d-flex justify-content-center page-content">
					    <Col xs={12} md={6}>
					        <div className="card">
					        	<div className="tabs d-flex align-items-center">
					        		<div onClick={() => {setTab1(true);setTab2(false);setTab3(false);setTab4(false);}} className="tab" style={{backgroundColor:tab1?'rgb(179, 208, 255, 0.3)':''}}>Parent name combination</div>
					        		<div onClick={() => {setTab2(true);setTab1(false);setTab3(false);setTab4(false);}} className="tab" style={{backgroundColor:tab2?'rgb(179, 208, 255, 0.3)':''}}>Ethnic names</div>
					        		<div onClick={() => {setTab3(true);setTab1(false);setTab2(false);setTab4(false);}} className="tab" style={{backgroundColor:tab3?'rgb(179, 208, 255, 0.3)':''}}>Religious names</div>
					        		<div onClick={() => {setTab4(true);setTab1(false);setTab2(false);setTab3(false);}} className="tab" style={{backgroundColor:tab4?'rgb(179, 208, 255, 0.3)':''}}>Name with specific meaning</div>
					        	</div>
					            <div className="card-body d-flex justify-content-start">
					            	{tab1&&<div className="tab1-content">
						                <form className="w-100" action="/" method="POST">
						                    <label>Generate unique baby names by combining parent's names</label>
						                    <div className="form-group w-100">
						                        <input onChange={(e) => setParent1name(e.target.value)} type="text" className="form-control w-100" id="parent1Name" name="parent1Name" placeholder="Enter Parent 1 Name" required/>
						                    </div>
						                    <div className="form-group w-100">
						                        <input onChange={(e) => setParent2name(e.target.value)} type="text" className="form-control w-100" id="parent2Name" name="parent2Name" placeholder="Enter Parent 2 Name" required/>
						                    </div>
						                    <div className="form-group w-100">
						                        <label>Gender</label>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="male" value="male" required/>
						                            <label className="form-check-label" for="male">
						                                Male
						                            </label>
						                        </div>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="female" value="female" required/>
						                            <label className="form-check-label" for="female">
						                                Female
						                            </label>
						                        </div>
						                    </div>
						                    <button onClick={handleSubmit} type="submit" className="btn btn-primary">Generate Baby Names</button>
						                </form>
						            </div>}
						            {tab2&&<div className="tab2-content">
						                <form className="w-100" action="/" method="POST">
						                    <label>Generate ethnic names from a particular region or country</label>
						                    <div className="form-group w-100">
						                        <input onChange={(e) => setEthnicBackground(e.target.value)} type="text" className="form-control w-100" id="origin" name="origin" placeholder="Enter Geographic region or country" required/>
						                    </div>
						                    <div className="form-group w-100">
						                        <label>Gender</label>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="male" value="male" required/>
						                            <label className="form-check-label" for="male">
						                                Male
						                            </label>
						                        </div>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="female" value="female" required/>
						                            <label className="form-check-label" for="female">
						                                Female
						                            </label>
						                        </div>
						                    </div>
						                    <button onClick={handleEthnicNameSubmit} type="submit" className="btn btn-primary">Generate Baby Names</button>
						                </form>
						            </div>}
						            {tab3&&<div className="tab3-content">
						                <form className="w-100" action="/" method="POST">
						                    <label>Generate baby names from specific religion</label>
						                    <div className="form-group w-100">
						                        <input onChange={(e) => setReligion(e.target.value)} type="text" className="form-control w-100" id="religion" name="religion" placeholder="Enter your preferred religion" required/>
						                    </div>
						                    <div className="form-group w-100">
						                        <label>Gender</label>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="male" value="male" required/>
						                            <label className="form-check-label" for="male">
						                                Male
						                            </label>
						                        </div>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="female" value="female" required/>
						                            <label className="form-check-label" for="female">
						                                Female
						                            </label>
						                        </div>
						                    </div>
						                    <button onClick={handleReligiousNameSubmit} type="submit" className="btn btn-primary">Generate Baby Names</button>
						                </form>
						            </div>}
						            {tab4&&<div className="tab4-content">
						                <form className="w-100" action="/" method="POST">
						                    <label>Generate baby names with specific meanings from different languages</label>
						                    <div className="form-group w-100">
						                        <input onChange={(e) => setMeaningOfName(e.target.value)} type="text" className="form-control w-100" id="meaning" name="meaning" placeholder="Enter the desired meaning of your baby's name." required/>
						                    </div>
						                    <div className="form-group w-100">
						                        <label>Gender</label>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="male" value="male" required/>
						                            <label className="form-check-label" for="male">
						                                Male
						                            </label>
						                        </div>
						                        <div className="form-check w-100">
						                            <input onChange={(e) => setGender(e.target.value)} className="form-check-input" type="radio" name="gender" id="female" value="female" required/>
						                            <label className="form-check-label" for="female">
						                                Female
						                            </label>
						                        </div>
						                    </div>
						                    <button onClick={handleMeaningfulNameSubmit} type="submit" className="btn btn-primary">Generate Baby Names</button>
						                </form>
						            </div>}
					            </div>
					        </div>
					    </Col>
					    <Col xs={12} md={6}>
					        <div className="card">
					            <div className="card-body">
					            	<div className="d-flex align-items-center">
						                <div className="bold">Generated names</div>
						                <div className="save-form">
						                    {tab1&&<button onClick={handleSubmit} className="btn repeat-btn">repeat <i className="bi bi-arrow-repeat"></i></button>}
						                    {tab2&&<button onClick={handleEthnicNameSubmit} className="btn repeat-btn">repeat <i className="bi bi-arrow-repeat"></i></button>}
						                    {tab3&&<button onClick={handleReligiousNameSubmit} className="btn repeat-btn">repeat <i className="bi bi-arrow-repeat"></i></button>}
						                    {tab4&&<button onClick={handleMeaningfulNameSubmit} className="btn repeat-btn">repeat <i className="bi bi-arrow-repeat"></i></button>}
						                </div>
						            </div>
									{(content.length > 0)?(<div className="h-100 w-100">
										{content.map((value, index) => {
											return 	<div className="list-item d-flex align-items-center">
														<div>{value.name}</div>
														<div className="save-form">
															<input type="text" name="name" value={ value.name } className="hide"/>
															<input type="text" name="suggested_by" value="ai" className="hide"/>
															<button disabled={value?.saved?true:false} onClick={(e) => {e.preventDefault(); saveName(value.name);}} className="btn btn-primary save-btn save-generated-name" id="save-generated-name">{(value?.saved)?'Saved':'Save'}</button>
														</div>
													</div>
										})}
									</div>):(
										<div className="h-100 w-100 d-flex align-items-center justify-content-center graybackground">
											<h5 className="graytext">No results</h5>
										</div>
									)}
					            </div>
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}